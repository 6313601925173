<template>
    <v-card>
        <v-toolbar dark color="primary">
            <v-toolbar-title>{{$t('customer_form')}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="formClose">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-items>
                <v-btn dark text v-if="snackbar.save_button" @click="reset">{{$t('save')}}</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-stepper v-model="e6" vertical>
            <v-stepper-step :complete="e6 > 1" step="1">
                {{$t('customer_form')}}
                <small>{{$t('fill_out_the_customer_record_form')}}</small>
            </v-stepper-step>

            <v-stepper-content step="1">
                <form class="px-2">
                    <v-row>
                        <v-col cols="12"
                               sm="6" md="4" class="mt-3">
                            <v-text-field v-model="formData_1.groupName"
                                          :label="$t('group_name')"
                                          dense
                                  outlined
                                          clearable
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4" class="mt-3">
                            <v-text-field v-model="formData_1.numberOfPerson"
                                          :label="$t('no_of_person')"
                                          clearable
                                          dense
                                  outlined
                                          required
                                          :error-messages="numberOfPersonErrors"
                                          @input="$v.formData_1.numberOfPerson.$touch()"
                                          @blur="$v.formData_1.numberOfPerson.$touch()"></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4" class="mt-3">
                            <v-menu v-model="snackbar.menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="formData_1.travelDate"
                                                  :label="$t('travel_date')"
                                                  clearable
                                                  dense
                                  outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="formData_1.travelDate" :error-messages="travelDateErrors"
                                               @input="$v.formData_1.travelDate.$touch()"
                                               @blur="$v.formData_1.travelDate.$touch()"></v-date-picker>

                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="formData_1.activation" :items="Activation" item-text="value"
                                            item-value="id"
                                            dense
                                  outlined
                                            :label="$t('activation')"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="formData_1.destination" :items="Destination" item-text="value"
                                            item-value="id"
                                            dense
                                  outlined
                                            :label="$t('destination')"></v-autocomplete>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="formData_1.duration" :items="Duration" item-text="value"
                                            item-value="id"
                                            dense
                                  outlined
                                            :label="$t('duration')"></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="formData_1.packageName"
                                    :items="Packages"
                                    :label="$t('package')"
                                            dense
                                  outlined
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6" md="4">
                            <v-menu v-model="snackbar.menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    class="col-sm-6 col-md-4"
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="formData_1.issuedDate"
                                                  :label="$t('issued_date')"
                                                  clearable
                                                  dense
                                  outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="formData_1.issuedDate" :error-messages="issuedDateErrors"
                                               @input="$v.formData_1.issuedDate.$touch()"
                                               @blur="$v.formData_1.issuedDate.$touch()"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <div class="col-12">
                            <v-btn dark depressed class="mr-4 green darken-1" @click="form_1Post">{{$t('save')}}</v-btn>
                            <!-- <v-btn dark depressed class="mr-4 blue darken-1" @click="form_next" :disabled="!snackbar.next_button">Next</v-btn>-->
                        </div>
                    </v-row>
                </form>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">{{$t('customer_information')}}</v-stepper-step>

            <v-stepper-content step="2">
                <v-expansion-panels>
                    <v-expansion-panel v-for="(item,i) in snackbar.number_of_second_form" :key="i">
                        <v-expansion-panel-header>{{$t('person')}} {{item}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <form class="px-2">

                                <v-card class="my-12">
                                    <v-toolbar class="darken-1" color="blue" dark fixed>
                                        <v-toolbar-title>
                                            {{$t('find_person_id')}}
                                        </v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>

                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                        :items="['Passport','Citizen']"
                                                        :label="$t('type')"
                                                          dense
                                  outlined
                                                        v-model="trackType"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                        :label="$t('enter_your_id')"
                                                        v-model="trackID"
                                                              dense
                                  outlined
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="d-flex" cols="12" sm="12">
                                                <v-spacer></v-spacer>
                                                <v-btn @click="getUserData(i)" class="mx-2" color="primary" dark small>
                                                    <v-icon dark> search</v-icon>
                                                    {{$t('search')}}
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>


                                <v-row>
                                    <v-text-field
                                            :label="$t('full_name')"
                                            v-model="formData_2[i].fullName"
                                            clearable
                                                  dense
                                  outlined
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>
                                    <v-autocomplete
                                            v-model="formData_2[i].gender"
                                            :items="gender"
                                            :label="$t('gender')"
                                                    dense
                                  outlined
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"
                                    ></v-autocomplete>
                                    <v-col cols="12"
                                           sm="6" md="4">
                                        <v-menu v-model="snackbar.menu4"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                class="col-sm-6 col-md-4"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="formData_2[i].dateofBirth"
                                                              :label="$t('dob')"
                                                              clearable
                                                              dense
                                  outlined
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="formData_2[i].dateofBirth"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col cols="12"
                                           sm="6" md="4">
                                        <v-autocomplete v-model="formData_2[i].ageGroup" :items="AgeGroup"
                                                        item-text="id"
                                                        item-value="value"
                                                        dense
                                  outlined
                                                        :label="$t('age_group')"></v-autocomplete>
                                    </v-col>

                                    <v-text-field
                                            :label="$t('nationality')"
                                            v-model="formData_2[i].nationality"
                                            clearable
                                                  dense
                                  outlined
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>
                                    <v-autocomplete
                                            v-model="formData_2[i].country"
                                            :items="countries"
                                            :label="$t('country')"
                                            clearable
                                                    dense
                                  outlined
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"
                                            @change="getState"
                                    ></v-autocomplete>
                                    <!--<v-autocomplete
                                            v-model="formData_2[i].state"
                                            :items="state"
                                            label="State"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            class="col-sm-6 col-md-4"
                                    ></v-autocomplete>-->

                                    <v-text-field
                                            :label="$t('citizenshipNumber')"
                                            v-model="formData_2[i].citizenShipNumber"
                                            clearable
                                                  dense
                                  outlined
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>
                                    <v-text-field
                                            :label="$t('passport_number')"
                                            v-model="formData_2[i].passportNumber"
                                            clearable
                                                  dense
                                  outlined
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>
                                    <v-text-field
                                            :label="$t('licenseNumber')"
                                                  dense
                                  outlined
                                            v-model="formData_2[i].licenseNumber"
                                            clearable
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>
                                    <v-text-field
                                            :label="$t('otherID')"
                                                  dense
                                  outlined
                                            v-model="formData_2[i].otherID"
                                            clearable
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>

                                    <v-text-field
                                            :label="$t('documents')"
                                                  dense
                                  outlined
                                            v-model="formData_2[i].document"
                                            clearable
                                            class="col-sm-6 col-md-4"
                                    ></v-text-field>

                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-switch
                                                class="col-sm-6 col-md-4"
                                                v-model="formData_2[i].isVerifiedDocument"
                                                :label="$t('is_verified_document')"
                                                clearable
                                        ></v-switch>
                                    </v-col>

                                </v-row>
                            </form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <div class="col-4">
                    <v-btn dark depressed class="mr-4 green darken-1" @click="form_2Post">{{$t('save')}}</v-btn>
                </div>
            </v-stepper-content>
        </v-stepper>
        <v-snackbar v-model="snackbar.snackbar" :timeout="snackbar.timeout" top color="error">
            {{ snackbar.text }}
            <v-btn text @click="snackbar.snackbar = false">{{$t('cancel')}}</v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    /*import { email, maxLength,, sameAs } from "vuelidate/lib/validators";*/
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import moment from "moment";

    export default {
        name: "TravelTrekkingCreateForm",
        props: ["pendingData"],

        watch: {
            searchCitizen(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                    axios.get("Track/GetCitizenIDListAsync", {
                        params: {
                            CitizenID: val
                        }
                    })
                        .then(res => {
                            this.citizenshipData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            searchPassport(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    axios.get("Track/GetPassportIDListAsync", {
                        params: {
                            PassportNumber: val
                        }
                    })
                        .then(res => {
                            this.passportData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
        },

        data() {
            return {
                trackType: "Passport",
                trackID: "",
                citizenshipData:[],
                passportData:[],
                dropdownData:[],
                isLoading: false,
                searchCitizen: null,
                searchPassport: null,

                Activation: [],
                Duration: [],
                Destination: [],
                AgeGroup: [],
                e6: 1,
                step: 1,
                snackbar: {
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    menu3: false,
                    menu4: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    HotelCustomerID: 1,
                    price: 0,
                    ServicePrice: 0
                },
                paymentType: ['CASH', 'CHEQUE', 'CARD'],
                formData_1: {},
                formData_2: [],
                formData_2_Object: {},
                formDatas: {},
                BillForm: {},
                rooms: [],
                services: [],
                Packages: [],
                RoomObject: {},
                countries: [],
                state: [],
                gender: [],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        computed: {
            numberOfPersonErrors() {
                const errors = [];
                if (!this.$v.formData_1.numberOfPerson.$dirty) return errors;
                !this.$v.formData_1.numberOfPerson.required &&
                errors.push("Number of person is required");
                return errors;
            },
            travelDateErrors() {
                const errors = [];
                if (!this.$v.formData_1.travelDate.$dirty) return errors;
                !this.$v.formData_1.travelDate.required &&
                errors.push("Check in date is required");
                return errors;
            },
            issuedDateErrors() {
                const errors = [];
                if (!this.$v.formData_1.issuedDate.$dirty) return errors;
                !this.$v.formData_1.issuedDate.required &&
                errors.push("Check Out date is required");
                return errors;
            },
        },
        validations: {
            formData_1: {
                numberOfPerson: {required},
                travelDate: {required},
                issuedDate: {required},
            }
        },
        beforeMount() {
            this.addForm_2_Parameter();
        },
        mounted() {
            this.formData();
        },

        methods: {

            getUserData(index) {
                if (this.trackID) {
                    this.userInfo = []
                    axios.get('Track/GetPersonInformationByID', {
                        params: {
                            ID: this.trackID,
                            Type: this.trackType
                        }
                    }).then(response => {
                        console.log(this.formData_2[index], response.data)
                        this.formData_2[index].fullName = response.data.fullName
                        this.formData_2[index].country = response.data.country
                        this.formData_2[index].nationality = response.data.nationality
                        this.formData_2[index].state = response.data.state
                        this.formData_2[index].gender = response.data.gender
                        this.formData_2[index].citizenShipNumber = response.data.citizenShipNumber
                        this.formData_2[index].passportNumber = response.data.passportNumber
                        this.formData_2[index].passportNumber = response.data.passportNumber
                        this.formData_2[index].licenseNumber = response.data.licenseNumber
                        this.formData_2[index].dateofBirth = moment(response.data.dateOfBirth).format('YYYY-MM-DD')
                        if (response.data.country) {
                            this.getState(response.data.country)
                        }
                        this.trackID = ""
                    })
                }
            },

            getCitizenData(index,data){
                axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                    params:{
                        CitizenShipNumber: data.value
                    }
                }).then(response=>{
                    this.formData_2[index] = response.data
                    if(response.data.country){
                        this.getState(response.data.country)
                    }
                }).catch(err=>{
                    console.log(err)
                });
            },
            getPassportData(index,data){
                axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                    params:{
                        PassportNumber: data.value
                    }
                }).then(response=>{
                    this.formData_2[index] = response.data
                    if(response.data.country){
                        this.getState(response.data.country)
                    }
                }).catch(err=>{
                    console.log(err)
                });
            },

            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            formClose(val) {
                console.log(val);
                this.$emit("formResponse", this.servicesData);
            },
            addForm_2_Parameter() {
                this.formData_2.push({
                    fullName: "",
                    country: this.countries,
                    state: "",
                    gender: "",
                    ageGroup: "",
                    citizenShipNumber: "",
                    passportNumber: "",
                    licenseNumber: "",
                    otherID: "",
                    document: "",
                    nationality: "",
                    dateofBirth: null,
                    isVerifiedDocument: false
                });
                this.dropdownData.push({
                    citizenShipNumber:"",
                    passportNumber:""
                })
            },
            async formData() {

                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))

                axios.get("Shared/GenderList").then(response => {
                    this.gender = response.data;
                }).catch(error => console.log(error.response))

                axios.get('TravelTrekkingGuide/DDLTravelTrekkingGuideDestinationByIDAsync').then(response => {
                    this.Destination = response.data
                }).catch(error => console.log(error.response))

                axios.get('TravelTrekkingGuide/DDLTravelTrekkingGuideDurationByIDAsync').then(response => {
                    this.Duration = response.data
                }).catch(error => console.log(error.response))

                axios.get('TravelTrekkingGuide/DDLTravelTrekkingGuideActivationByIDAsync').then(response => {
                    this.Activation = response.data
                }).catch(error => console.log(error.response))

                axios.get('TravelTrekkingGuide/DDLTravelTrekkingGuideServiceListAsync').then(response => {
                    this.Services = response.data
                }).catch(error => console.log(error.response))
                axios.get("Shared/GetAgeGroupList").then(response => {
                    this.AgeGroup = response.data;
                }).catch(error => console.log(error.response))
                axios.get('TravelTrekkingGuide/DDLTravelTrekkingGuidePackageListAsync').then(response => {
                    this.Packages = response.data
                }).catch(error => console.log(error.response))
            },
            getServicePrice() {
                let param = {
                    "HotelCustomerServiceID": this.formData_1.Service
                }
                axios
                    .post("Hotel/GetHotelServiceAmount", param)
                    .then(response => {
                        this.snackbar.ServicePrice = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            getPackagePrice(val) {
                console.log(val);
                axios
                    .post("Hotel/GetHotelPakageAmount", val)
                    .then(response => {
                        this.snackbar.price = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            getPrice() {

                let fareAmountParam = {
                    "roomIDs": this.formData_1.Room,
                    "packageID": parseInt(this.formData_1.Package),
                    "isCustomerOnPackage": this.formData_1.isCustomerOnPackage,
                    "numberOfPerson": parseInt(this.formData_1.numberOfPerson)
                }
                axios
                    .post("Hotel/GetPackageOrRoomFareAmountAsync", fareAmountParam)
                    .then(response => {
                        this.snackbar.price = response.data;
                        console.log(this.snackbar.price);
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Could not Fetch the price";
                    });
            },
            form_1Post() {
                this.$v.formData_1.$touch();
                if (this.$v.formData_1.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    this.formData_1.numberOfPerson = parseInt(this.formData_1.numberOfPerson)
                    axios
                        .post("TravelTrekkingGuide/InsertIntoCustomerInformationAsync", this.formData_1)
                        .then(response => {
                            this.$v.formData_1.$reset();
                            this.snackbar.success = response.data.success;
                            this.snackbar.number_of_second_form = this.formData_1.numberOfPerson;
                            this.snackbar.next_button = !this.snackbar.next_button;
                            this.snackbar.HotelCustomerID = response.data.id;
                            this.snackbar.error = false;
                            this.snackbar.snackbar = false;
                            for (
                                let step = 1;
                                step < this.snackbar.number_of_second_form;
                                step++
                            ) {
                                console.log("inside loop", step);
                                this.addForm_2_Parameter();
                            }
                            this.e6++;
                            this.formData_1 = {};
                        })
                        .catch(() => {
                            this.snackbar.error = true;
                            this.snackbar.snackbar = true;
                            this.snackbar.success = false;
                        });
                }
            },
            form_2Post() {
                /*this.e6 = 4;*/
                axios
                    .post(
                        "TravelTrekkingGuide/InsertIntoPersonInformationAsync",
                        this.formData_2
                    )
                    .then(response => {
                        if (response.data.success) {
                            this.snackbar.success = response.data.success;
                            this.snackbar.number_of_second_form = 1;
                            this.formData_2_Object = {};
                            this.snackbar.next_button = false;
                            this.snackbar.save_button = true;
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer added succcessfully"
                            this.servicesData.color = "success"
                            this.formClose()
                        }
                    })
                    .catch(() => {
                        this.snackbar.error = true;
                        this.snackbar.snackbar = true;
                        this.snackbar.success = false;
                        this.snackbar.text = "Internal Server Error, Please Contact Admin";
                    });
            },
            reset() {
                this.save_button = false;
                this.e6 = 1;
                this.$emit("FormClose", this.dialogue);
                this.snackbar.next_button = true;
            },
            checkRate(ActualRate, PaidPrice) {
                this.BillForm.DueAmount = (ActualRate.price + ActualRate.ServicePrice) - PaidPrice;
            },
            async GetRoomStatus() {
                let data = {
                    "bookedFromDate": this.formData_1.travelDate,
                    "bookedToDate": this.formData_1.issuedDate,
                    "isDropDown": true
                };
                let roomlist = await axios.post(
                    "Hotel/RoomStatusListAsync",
                    data
                )
                console.log(roomlist.data)
                this.rooms = roomlist.data;
            }
        }
    };
</script>

<style scoped>
</style>
