<template>
  <v-card>
    <v-toolbar fixed dark color="blue" class="darken-1">
      <v-toolbar-title> Import Export </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>     
        <v-row class="pa-8">
            <v-file-input dense label="Import File" accept=".xlsx" outlined v-model="formdata.import"></v-file-input>
  <v-btn @click.stop="onImport" class="primary float-right mx-4">
                <v-icon>publish</v-icon>
                Upload
            </v-btn>
        </v-row>   
                    
    </v-card-text>
    <v-card-actions>
            
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportExportModalComponent",
    props:['ieData'],
  data() {
    return {
        formdata:{
            import:""
        },
        importData:{
            modal:false,
            message:""
        }
    };
  },
  computed: {},
  methods: {

      dialogueClose(){
        console.log(this.importData);
        this.$emit("importExportModalResponse", this.importData);

      },

    async onImport() {
      var form = new FormData();

      form.append("formFile", this.formdata.import);
      let ImportResponse = await axios.post(this.ieData.import, form);
      if (ImportResponse.data.success) {
        this.formdata.import = null;
        this.importData.modal = true
        this.importData.message = "File imported Successfuly !!!"
        this.dialogueClose();
      } else {
        return false;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>